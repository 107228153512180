import React, { useEffect, useState } from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { navigate, graphql } from 'gatsby';

import pic1 from '../images/jobs/1.png';
import pic2 from '../images/jobs/2.png';
import pic3 from '../images/jobs/3.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import {
	Header,
	Headline,
	Ulist,
	Title,
	GradientButton,
	Wrapper,
	Pic1,
	Pic2,
	Pic3,
	Section1,
	Section2,
	Section3,
	OfferHeadline,
	JobsHeader,
	JobsHeadline,
	Offer1,
	Offer2,
	Offer3,
	Offer4,
	LocationCont,
	LocationText,
	PositionBox,
	Body,
	LocationImg,
	PositionCount,
	LocationArrow,
} from '../styles';

configureAnchors({ offset: -80, scrollDuration: 700 });

const Jobs = ({ data }) => {
	const jobsList = data.allGoogleJobsSheet.edges;
	const [jobs, setJobs] = useState([]);

	// transforming jobs data in format needed for display in jsx
	const refineJobs = jobsList => {
		const refinnedJobs = [];
		let Location = '';
		let positions = [];
		jobsList.map(({ node: { location, position, amount } }, i) => {
			if (!Location) {
				Location = location;
				positions.push({ position, amount });
			} else if (location === Location) {
				positions.push({ position, amount });
				if (i === jobsList.length - 1) {
					refinnedJobs.push({ location: Location, positions });
				}
			} else {
				refinnedJobs.push({ location: Location, positions });
				Location = location;
				positions = [{ position, amount }];
				if (i === jobsList.length - 1) {
					refinnedJobs.push({ location, positions });
				}
			}
		});
		setJobs(refinnedJobs);
	};

	useEffect(() => {
		refineJobs(jobsList);
	}, []);

	return (
		<Layout>
			<MetaData
				title="Envel - Jobs"
				description="We are always looking for superstar talent, join the team or become an Envel brand ambassador. View our open positions to become an Envelenger."
				image="images/social/social.png"
			/>

			<Section1 row alignCenter justifyCenter>
				<div data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
					<JobsHeader>Jobs and Internships</JobsHeader>
					<JobsHeadline>
						We are always looking for superstar talent. Though the skills are necessary, this is not just about a job.
						it&apos;s about playing an instrumental role in changing the lives of millions of people by empowering them
						with a banking service they deserve.
					</JobsHeadline>
				</div>
				<Pic1 src={pic1} alt="pic1" data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100" />
			</Section1>
			<Section2 alignCenter>
				<JobsHeader center data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
					Work for Envel
				</JobsHeader>
				<Pic2 src={pic3} alt="pic3" data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100" />
				<Offer1 data-sal="slide-up" data-sal-duration="1000" data-sal-delay="300">
					dress how you want!
				</Offer1>
				<Offer2 data-sal="slide-up" data-sal-duration="1000" data-sal-delay="550">
					highly creative environment
				</Offer2>
				<Offer3 data-sal="slide-up" data-sal-duration="1000" data-sal-delay="800">
					physical and personal wellness opportunities
				</Offer3>
				<Offer4 data-sal="slide-up" data-sal-duration="1000" data-sal-delay="1000">
					a work environment where we put equality first
				</Offer4>
				<OfferHeadline data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
					We want you to have the tools to execute your role effectively. if you don&apos;t feel comfortable at work,
					then we&apos;ve failed miserably.
				</OfferHeadline>
			</Section2>
			<Section3 row alignCenter>
				<Pic3 src={pic2} alt="pic2" data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100" />
				<div data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
					<Header>Be an Envel brand ambassador!</Header>
					<Headline>
						We are looking for enthusiastic, outgoing ambassadors who can help us spread their excitement about Envel
					</Headline>
					<Ulist>
						<li>
							are you a student living on college campus looking to advocate change in the the way young people deal
							with their finances?
						</li>
						<li>do you want to help revolutionize the industry and help people take back control of their money?</li>
						<li>are you ready to champion the very first, true ai-powered mobile banking experience?</li>
					</Ulist>
				</div>
			</Section3>
			<div data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
				<Title center>If you answered &apos;YES&apos;, then we want you as an Envel brand ambassador!</Title>
				<Wrapper alignCenter>
					<a href="mailto:info@envel.ai">
						<GradientButton round>info@envel.ai</GradientButton>
					</a>
				</Wrapper>
			</div>
			<div data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
				<ScrollableAnchor id="positions">
					<JobsHeader center>Join the Envelengers!</JobsHeader>
				</ScrollableAnchor>
				<JobsHeadline className="positions" center>
					We are all about building the best products with superhero talent, so if you are extraordinary and you want to
					work with a team and company that fully appreciates this, then let’s join forces!
				</JobsHeadline>
			</div>
			{/* <Wrapper row wrap justifyEvenly data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
				{jobs.map(({ location, positions }) => (
					<LocationCont key={location}>
						<Wrapper row alignCenter height="auto">
							<LocationImg />
							<LocationText>{location}</LocationText>
						</Wrapper>
						{positions.map(({ position, amount }, i) => (
							<PositionBox key={i} onClick={() => navigate(`/jobs/${location}/${position}/`)}>
								<Wrapper height="auto" row>
									<Wrapper row alignCenter>
										<Body font="0.93rem">{position} </Body>
										{amount > 1 ? <PositionCount>{amount}</PositionCount> : null}
									</Wrapper>
									<LocationArrow />
								</Wrapper>
							</PositionBox>
						))}
					</LocationCont>
				))}
			</Wrapper> */}
			<div data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
				<Title center>Email your resume to</Title>
				<Wrapper alignCenter>
					<a href="mailto:careers@envel.ai">
						<GradientButton round>careers@envel.ai</GradientButton>
					</a>
				</Wrapper>
			</div>
		</Layout>
	);
};

export default Jobs;

export const jobsQuery = graphql`
	query {
		allGoogleJobsSheet(sort: { order: ASC, fields: location }) {
			edges {
				node {
					location
					position
					amount
				}
			}
		}
	}
`;
